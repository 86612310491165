

/*Question styles*/

/*radio*/
.question_container .radio input[type="radio"] {
    display:none;
}
.question_container .radio input[type="radio"]+label {
    background-color:#f6f5f7;
    border-radius: 25px;
    padding: 12px 25px;
    display:inline-block;
    text-align: center;
    box-sizing:border-box;
    cursor:pointer;
}
.question_container .radio input[type="radio"]:hover+label {
    background-color:#80738a;
    color:#f6f5f7;
}
.question_container .radio input[type="radio"]:checked+label {
    background-color:#a81758;
    color:#f6f5f7;
}

.question_container .checkbox#dept, .question_container .checkbox#sub_dept, .question_container .checkbox#region,  .question_container .checkbox#region_group {
    width:95%;
}

