button.darkButton {
  padding: 10px 30px;
  text-align: center;
  border-radius: 25px;
  font-family: "futura-pt-demi", sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #281d2d;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
  outline: none;
  background-color: #281d2d;
  color: #fff;
}

button.darkButton.pink {
  transition: all 0.2s;
  box-shadow: none;
}

.goalsrw .darkButton {
  text-transform: none;
}

.goalsrw .darkButton.full_width {
  margin: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

button.darkButton .svg-inline--fa {
  margin-top: 0;
}

button.darkButton.support-button {
  width: fit-content;
  margin-bottom: 0;
  text-transform: none;
}

button.darkButton:hover {
  background-color: #fff;
  color: #281d2d;
}

button.darkButton.pink:hover {
  color: #fff;
  background-color: rgb(229, 34, 98);
}

button.darkButton.wb:hover {
  border: 1px solid #281d2d;
}

button.darkButton.support-button:hover,
.goalsrw button.darkButton.nb:hover {
  border: 1px solid #fff;
}

button.darkButton .icon {
  margin-left: 5px;
  margin-right: 0;
  margin-top: 0;
  transition: all 0.5s ease;
}
button.darkButton:hover .icon {
  margin-left: 10px;
  margin-right: -5px;
}
.reminder_body button.darkButton .icon {
  margin-top: 3px;
}
