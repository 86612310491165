.glowrm {
  min-height: 100vh;
  overflow: auto;
}
.glowrm h1,
.glowrm h2,
.glowrm h1 a {
  color: #fff;
  position: relative;
  z-index: 1;
}
.glowrm h1 {
  margin: 30px 0px -10px 50px;
  text-transform: capitalize;
}
.platform {
  padding: 200px 0 200px 0;
}

.promo_box_row {
  display: flex;
  padding: 0 50px;
}

.glowrm .square,
.glowrm .clear {
  position: relative;
  flex: 0 0 24%;
  margin: 0 2% 2% 0;
}
.glowrm .promo_box_row .square:last-of-type {
  margin-left: 0;
}
.glowrm .square {
  background-color: #2a202f;
  border-radius: 30px;
}

.glowrm .square:after,
.glowrm .clear:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.glowrm .square_content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px;
  flex-wrap: wrap;
}
.glowrm .clear .square_content {
  padding: 0;
}
.glowrm .square.wide {
  position: relative;
  flex: 0 0 48%;
  background-color: #2a202f;
  border-radius: 30px;
  margin: 0 2% 2% 0;
}
.glowrm .clear.wide {
  position: relative;
  flex: 0 0 48%;
  margin: 0 2% 2% 0;
}
.glowrm .square.wide:after,
.glowrm .clear.wide:after {
  content: "";
  display: block;
  padding-bottom: 48%;
}
.glowrm .square.full {
  position: relative;
  flex: 0 0 98%;
}
.glowrm .square.full:after {
  content: "";
  display: block;
  padding-bottom: 40%;
}
.glowrm .overlay {
  padding: 20px 30px;
}
.glowrm .overlay h3 {
  color: #fff;
}
.glowrm .square_content.area_box .icon {
  color: #fff;
}
.glowrm .square_content.area_box#individual-report-overview {
  background-image: url("./images/health-report.jpg");
}
.glowrm .square_content.area_box#track-mood-tracker {
  background-image: url("./images/mood-tracker-coming-soon1.jpg");
}
.glowrm .square_content.area_box#track-sleep-tracker {
  background-image: url("./images/sleep-tracker-coming-soon.jpg");
}
.glowrm .area_box .overlay {
  background-image: linear-gradient(
    to top,
    rgba(38, 28, 44, 0) 0%,
    rgba(38, 28, 44, 1) 100%
  );
  height: 100%;
}
.glowrm h1.section_title {
  font-size: 40px;
  margin: 0 0 20px 0;
  text-align: center;
}

#main_container.authors {
  background-color: rgb(40, 29, 45);
}
.author_with {
  font-size: 22px;
  margin: 30px 0;
  text-align: center;
}

.authors,
.author_with a {
  color: #fff;
}
.authors .video_container,
.authors .author_desc_container {
  max-width: 90%;
  width: 600px;
  margin: 30px auto;
}
.authors .author_photo {
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-right: 20px;
  border-radius: 50%;
  background-position: center center;
  background-size: 60px;
  top: -5px;
  position: absolute;
  margin-left: -80px;
}
.authors h1.section_title {
  padding-left: 80px;
  position: relative;
}
.breadcrumb {
  text-align: center;
  color: #fff;
  margin-top: -25px;
  padding-bottom: 25px;
}
.breadcrumb a {
  color: #fff;
  text-decoration: underline;
  text-transform: capitalize;
}
.breadcrumb span {
  font-size: 30px;
  margin: 0 10px;
}
a.back-btn {
  display: inline-block;
  margin-left: 50px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: #64596b;
  text-align: center;
  padding-top: 15px;
  box-sizing: border-box;
  position: absolute;
  z-index: 20;
}
.icon.return-arrow.back {
  color: #fff;
  font-size: 30px;
}

.authorPage .results-img {
  height: 100vh;
}

.authorPage .glowrm_slider {
  margin: 0 100px;
}

.authorPage .glowrm_slider h1 {
  margin-left: 0;
}

.authorPage .hero-contents {
  padding: 0 100px;
}

.authorPage .hero-icon {
  width: 160px;
  height: 160px;
  margin-top: 26px;
  margin-right: 36px;
}

.authorPage .hero-info-container {
  width: calc(100% - 205px);
  align-items: center;
}

.authorPage .hero-container {
  display: flex;
}

.authorPage .page-cont .gradient-overlay .hero-contents h3 {
  width: 100%;
}

.authorPage .author_tiles {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin: -65px 100px 67px 100px;
}

.authorPage .author_featured {
  height: 424px;
  width: 50%;
  border-radius: 43px;
  background-image: linear-gradient(to right, #62bf92 0%, #dafaa6 100%);
  padding: 3.5px;
}

.authorPage .author_featured_inner .innerSlide {
  border-radius: 43px;
}

.authorPage .author_featured_inner .innerSlide .innerFade {
  border-bottom-left-radius: 43px;
  border-bottom-right-radius: 43px;
}

.authorPage .author_featured_inner .innerSlide .innerContent {
  padding: 0 35px 30px 40px;
}

.authorPage .author_featured_inner .innerSlide .innerContent h2 {
  font-size: 35px;
  font-weight: 500;
  line-height: 43px;
  margin-bottom: 12px;
}

.authorPage .author_featured_inner .innerSlide .innerContent .length {
  font-size: 23px;
  font-weight: 400;
  line-height: 22px;
  text-transform: lowercase;
  font-family: "futura-pt-medium";
}

.authorPage .author_featured_inner .innerSlide .innerContent .category {
  color: rgba(255, 255, 255, 0.6);
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  font-family: "futura-pt-medium";
  line-height: 22px;
  letter-spacing: 2.8px;
  text-transform: uppercase;
}

.authorPage .author_featured_inner {
  width: 100%;
  height: 100%;
}

.authorPage .author_desc_container {
  display: flex;
  flex-direction: column;
  height: 375px;
  border-radius: 43px;
  background: #64596b;
  width: calc(50% - 64px);
  padding: 55px 10px 0 58px;
  margin-left: 40px;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.authorPage .author_desc_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  font-family: "futura-pt-medium";
}

.authorPage .author_desc_container .scrollable {
  overflow-y: scroll;
  padding-right: 21px;
  margin-bottom: 55px;
}

.authorPage .author_desc_container .author_desc_info {
  margin: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  font-family: "futura-pt-book";
}

.authorPage .author_desc_container .scrollable::-webkit-scrollbar {
  width: 4px;
}

.authorPage .author_desc_container .scrollable::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.authorPage .author_desc_container .scrollable::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2.5px;
  background: #291e2e;
}

.glowrm.track_page h1.section_title {
  margin: -70px auto 70px auto;
}

.authorPage .page-cont .gradient-overlay {
  justify-content: center;
  top: 18%;
  height: 82%;
}

.authorPage .page-cont .gradient-overlay .hero-contents .hero-subtitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 450;
  line-height: 45px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.authorPage .page-cont .gradient-overlay .hero-contents .hero-title {
  margin: 26px 0;
  color: #fff;
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  line-height: 100px;
}

.authorPage .page-cont .gradient-overlay .hero-contents .hero-description {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.authorPage .author_site {
  text-align: center;
  background-color: #fff;
  width: 169px;
  height: 34px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.authorPage .author_site a {
  color: #64596b;
  font-size: 26px;
  line-height: 26px;
  font-weight: 450;
}

.authorPage .author_desc_header_additiontal {
  margin-right: 45px;
  display: flex;
  align-items: center;
}

.authorPage .author_desc_header_about {
  text-transform: capitalize;
  margin-right: 27px;
  margin-bottom: 30px;
}

.authorPage .author_desc_header_video {
  width: 44px;
  height: 44px;
  cursor: pointer;
  background-image: url("./images/Play.svg");
  margin-right: 27px;
  margin-bottom: 30px;
}

.authorPage .authorPage_featured_info {
  display: flex;
  margin-top: 25px;
  margin-left: 40px;
  height: 44px;
  align-items: center;
}

.authorPage .authorPage_featured_star {
  background: url("./images/star.svg") center no-repeat;
  width: 39px;
  height: 44px;
}

.authorPage .authorPage_featured_text {
  color: #fff;
  font-size: 23px;
  font-style: normal;
  font-weight: 450;
  line-height: 22px;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  margin-left: 12px;
}

.authorPage .authorPage_featured_play {
  background: url("./images/play-featured.svg") center no-repeat;
  width: 104px;
  height: 104px;
  position: absolute;
  top: calc(50% - 52px);
  left: calc(50% - 52px);
}

.authorPage .video_container {
  width: 800px;
}

.authorPage .popup-overlay .popup-container {
  max-width: 800px;
}

.authorPage .popup-overlay .popup-container .popup-cta-container {
  display: flex;
  justify-content: center;
}

.authorPage .popup-overlay .popup-container .popup-cta {
  justify-content: center;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  text-decoration-line: underline;
  font-family: "futura-pt-medium";
  cursor: pointer;
  margin-bottom: 20px;
  text-underline-offset: 2px;
  border-top: none;
  width: fit-content;
}

.authorPage .popup-overlay .popup-container .popup-body {
  padding: 0;
}

.authorPage .popup-overlay .popup-container .popup-title {
  border-bottom: 1px solid #ddcedb;
}

@media (max-width: 1050px) {
  .glowrm h1.section_title,
  .glowrm h1 {
    font-size: 24px;
  }
  .promo_box_row {
    padding: 0 30px 50px 30px;
  }
  .authorPage .author_tiles {
    margin: -65px 60px 67px 60px;
  }
  .authorPage .glowrm_slider {
    margin: 0 60px;
  }
  .authorPage .hero-contents {
    padding: 0 60px;
  }
}
@media (max-width: 900px) {
  .authorPage .author_tiles {
    margin: -65px 30px 67px 30px;
  }
  .authorPage .glowrm_slider {
    margin: 0 30px;
  }
  .authorPage .hero-contents {
    padding: 0 30px;
  }
  .authorPage .author_desc_container {
    padding: 30px 10px 0 30px;
    width: calc(50% - 38px);
  }
  .authorPage .author_featured {
    height: 405px;
  }
  .authorPage .author_featured_inner .innerSlide .innerContent {
    padding: 0 30px 30px 30px;
  }
  .authorPage .authorPage_featured_info {
    margin: 30px;
  }
}
@media (max-width: 800px) {
  .promo_box_row {
    flex-wrap: wrap;
  }
  .promo_box_row .square {
    position: relative;
    flex: 0 0 48%;
  }
  .promo_box_row .square.wide {
    position: relative;
    flex: 0 0 98%;
  }
  .authorPage .video_container {
    width: 750px;
  }
  .authorPage .popup-overlay .popup-container {
    max-width: 750px;
  }
}
@media (max-width: 500px) {
  .glowrm h1 {
    margin-top: 10px;
  }
  .glowrm h1.section_title {
    margin-bottom: 40px;
  }
  .platform {
    padding-top: 160px;
  }
}
