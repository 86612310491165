.profile-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 180px;
}

.menu-left {
  width: 338px;
  height: 707px;
  border-radius: 20px 0px 0px 20px;
  border: 1px solid #64596b;
  border-right: none;
  padding: 39px 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-right {
  width: 706px;
  height: 785px;
  border-radius: 0px 20px 20px 0px;
  border: 1px solid #64596b;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.menu-left.simplify,
.menu-right.simplify {
  background: rgba(0, 0, 0, 0.2);
  width: 550px;
  height: 707px;
  border-radius: 20px;
  border: none;
  padding: 39px 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-photo {
  position: relative;
  width: 140px;
  height: 140px;
}
.profile-photo #preview {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover; /* maintain aspect ratio */
  overflow: hidden;
}

.photo-edit {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #ddcedb;
  background-color: #281d2d;
  color: #ddcedb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-name {
  margin-top: 17px;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
  font-family: "futura-pt-demi";
  font-size: 30px;
  font-weight: 500;
  line-height: 30px; /* 100% */
}

.profile-name.left {
  width: 100%;
  text-align: left;
}

.profile-name.wtf {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-name.wtf .wtf {
  cursor: pointer;
}

.profile-name.nm {
  margin: 0;
  display: flex;
  flex-direction: row;
}

.menu-left .btn-cont {
  width: 100%;
}

.profile-slider-btn {
  width: 272px;
  height: 29px;
  border-radius: 20px;
  background: rgba(100, 89, 107, 0.5);
  color: #fff;
  margin: 10px 0;
  padding: 19px 31px 19px 33px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}
.profile-slider-btn.active,
.field-cont.active,
.profile-slider-btn:hover,
.field-cont:hover {
  background-color: #64596b;
}

.profile-slider-btn.reminders {
  width: calc(100% - 74px);
  background: none;
  border: 1px solid rgba(100, 89, 107, 0.5);
  cursor: default;
}

.profile-slider-btn.reminders.content {
  height: auto;
  align-items: start;
}

.profile-slider-btn.reminders.content .icon {
  cursor: pointer;
}

.profile-slider-btn.reminders:hover {
  background-color: none;
}

.profile-slider-btn .menu-item,
.edit-field.lang .menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-field.lang .menu-item {
  cursor: pointer;
}

.profile-slider-btn .menu-item .slider-text,
.profile-btn .slider-text,
.menu-right .bold-sm-txt,
.profile-slider-btn .slider-text {
  font-family: "futura-pt-demi";
  font-size: 16px;
  line-height: 30px; /* 187.5% */
}

.profile-slider-btn .circle {
  width: 40px;
  height: 40px;
  background-color: #64596b;
  border-radius: 50%;
}

.profile-slider-btn .content-remind {
  width: 250px;
}

.menu-right .streak-circle .bold-sm-txt {
  line-height: 18px;
}

.profile-slider-btn .slider-icon {
  height: 30px;
  width: 30px;
  margin-right: 31px;
}

.profile-slider-btn .slider-chevron {
  height: 30px;
}

.profile-btn {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  color: #fff;
  margin-bottom: 18px;
  cursor: pointer;
}

.profile-btn.top {
  margin-top: 38px;
}

.profile-btn .smaller-icon {
  height: 20px;
  margin-right: 22px;
}

.menu-left .profile-footer {
  margin-top: 20px;
  line-height: 20px;
  color: #ddcedb;
  font-size: 12px;
  font-family: "futura-pt-medium";
  width: 100%;
  display: flex;
  flex-direction: column;
}

.menu-left .profile-footer a {
  color: #ddcedb;
}

.menu-right .edit-field {
  width: 336px;
}
.menu-right .edit-field.reminders {
  width: 450px;
}

.menu-right .edit-field .scrollable {
  width: 440px;
  height: 600px;
  padding-right: 10px;
  overflow-y: scroll;
}

.menu-right .edit-field .scrollable::-webkit-scrollbar {
  width: 4px;
}

.menu-right .edit-field .scrollable::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.menu-right .edit-field .scrollable::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2.5px;
  background: #291e2e;
}

.menu-right .edit-field .edit-title {
  font-family: "futura-pt-demi";
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.menu-right .edit-field .field-cont {
  width: 313px;
  height: 37px;
  border-radius: 20px;
  border: 1px solid #64596b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0 15px 23px;
  margin-top: 10px;
}

.menu-right .edit-field .error {
  color: #ef545a;
}
.menu-right .edit-field .field-cont.error {
  border: 1px solid #ef545a;
}

.menu-right .edit-field .error a {
  color: #ef545a;
  text-decoration: underline;
}

.menu-right .edit-field .field-cont span {
  color: rgba(221, 206, 219, 0.5);
  font-family: "futura-pt-book";
  font-size: 12px;
}

.menu-right .edit-field .field-cont input {
  background: none;
  outline: none;
  border: none;
  color: #fff;
  font-size: 16px;
  font-family: "futura-pt-medium";
}

.menu-right .edit-field .field-cont .select-cont {
  position: relative;
}

.select-cont .dropdown-body {
  z-index: 1;
  position: absolute;
  width: 95%;
  background-color: #463b4c;
  border-radius: 12px;
  max-height: 210px;
  overflow-y: auto;
}

.select-cont .dropdown-body::-webkit-scrollbar {
  width: 4px;
}

.select-cont .dropdown-body::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.select-cont .dropdown-body::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2.5px;
  background: #291e2e;
}

.dropdown-option {
  border-top: 1px solid #64596b;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  color: #ddcedb;
}

.select-cont .dropdown-body div:first-child {
  border-top: none;
}

.dropdown-option:hover {
  color: #fff;
  font-weight: 900;
}

/* .menu-right .edit-field .field-cont .select-cont select {
  width: 100%;
  height: 20px;
  border: none;
  outline: none;
  font-family: "futura-pt-medium", sans-serif;
  color: #fff;
  font-size: 16px;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select[name="Departments"] option {
  color: #290140; 
  background-color: white; 
} */

.menu-right .edit-field .field-cont.error .field-descr {
  color: #ef545a;
}

.menu-right .edit-field .field-cont .passw-field input {
  width: 85%;
}

.menu-right .edit-field .field-cont .passw-field .icon {
  color: #fff;
}

/* .menu-right .edit-field .field-cont .select-cont:after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 18px;
  margin-top: -2px;
  transform: translateY(-50%);
  border: solid #fff;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  pointer-events: none;
  transform: translateY(-50%) rotate(45deg);
} */

.menu-right .bold-sm-txt.cm {
  margin-top: 30px;
  cursor: pointer;
}

.menu-right .bold-sm-txt.cm a {
  color: #ddcedb;
}

.menu-right p.cm {
  height: 20px;
  color: #ddcedb;
  margin: 30px 0 18px 0;
}

.menu-right p.cm.profile {
  height: auto;
}

.menu-left p.cm {
  color: #ddcedb;
  margin: 10px 0 20px 0;
}

.menu-right .rm-box {
  width: 276px;
  height: 327px;
  border-radius: 20px;
  border: 1px solid #64596b;
  padding: 13px 30px 12px 30px;
}

.menu-right .rm-box.mt {
  margin-top: 10px;
}

.menu-right .streaks-cont {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.menu-right .streaks-cont .streak-clmn {
  display: flex;
  flex-direction: column;
  width: 45%;
  /* justify-content: space-between;
    align-items: center; */
}

.menu-right .streaks-cont .streak-clmn .streak-circle {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid #64596b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-right .streaks-cont .streak-clmn .streak-circle .icon {
  text-align: center;
  height: 40px;
  width: 40px;
}

.menu-right .streaks-cont .streak-name {
  padding-top: 5px;
  color: var(--DDCEDB, #ddcedb);
  font-family: "futura-pt-medium";
  font-size: 16px;
  line-height: 30px; /* 187.5% */
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}

.menu-right .streaks-cont .streak-clmn .streak-circle.blue .icon,
.menu-right .streaks-cont .streak-clmn .streak-circle.blue .profile-name,
.menu-right .streaks-cont .streak-clmn .streak-circle.blue .bold-sm-txt {
  color: #41c0d3;
}

.menu-right .streaks-cont .streak-clmn .streak-circle.green {
  border-color: #62bf92;
}
.menu-right .streaks-cont .streak-clmn .streak-circle.green .icon,
.menu-right .streaks-cont .streak-clmn .streak-circle.green .profile-name,
.menu-right .streaks-cont .streak-clmn .streak-circle.green .bold-sm-txt {
  color: #62bf92;
}

.menu-right .streaks-cont .streak-clmn-full {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
}

.menu-right .streaks-cont .streak-clmn-full .streak-line {
  margin-bottom: 20px;
}

.profile-slider-btn.large {
  height: 95px;
  padding-left: 15px;
}

.profile-slider-btn.large .ff-cont,
.edit-field .ff-cont {
  height: 100px;
  width: 100px;
  border-radius: 20px;
}

.profile-slider-btn.large .ff-cont img,
.edit-field .ff-cont img {
  height: 50px;
  padding: 25px 21.5px;
  background-size: cover;
  background-repeat: no-repeat;
}

.profile-slider-btn.large .text-cont {
  margin-left: 13px;
}

.profile-slider-btn.large .text-cont .pending {
  width: 90px;
  height: 21px;
  border-radius: 20px;
  background: var(--281D2D, #281d2d);
  color: #fff;
  font-family: "futura-pt-medium";
  font-size: 10px;
  line-height: 21px; /* 187.5% */
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
}

.profile-slider-btn.large.empty {
  background-color: #281d2d;
  border: 1px solid #64596b;
  .ff-cont {
    background-color: rgba(100, 89, 107, 0.5);
  }
}

.edit-field .cw {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.edit-field .cw .color-row {
  display: flex;
  flex-direction: row;
}

.edit-field .cw .circle-ff {
  height: 31px;
  width: 31px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 8px;
  cursor: pointer;
}

.edit-field .cw #green.circle-ff {
  background-color: #62bf92;
}

.edit-field .cw #blue.circle-ff {
  background-color: #41c0d3;
}

.edit-field .cw #red.circle-ff {
  background-color: #ef545a;
}

.edit-field .cw #orange.circle-ff {
  background-color: #f69358;
}

.edit-field .cw #yellow.circle-ff {
  background-color: #fcc954;
}

.edit-field .cw .circle-ff .inner-crc {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
  margin-top: 8px;
  pointer-events: none;
}

.menu-right .edit-field .field-cont .link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 24px;
}

.menu-right .edit-field .field-cont .link .icon {
  height: 15px;
  cursor: pointer;
}

.bold-sm-txt.centre {
  flex-direction: row;
  margin-top: 5px;
  cursor: pointer;
}

.bold-sm-txt.centre .icon {
  color: #ef545a;
  margin-right: 25px;
}

.menu-left a {
  width: 100%;
}

.explanation-cont textarea {
  margin-top: 30px;
  width: 90%;
  height: 150px;
  background-color: white;
  border-radius: 20px;
  border: none;
  color: #281d2d;
  resize: none;
  padding: 5%;
}

.menu-right .lang-option {
  margin-left: 20px;
}

@media screen and (max-width: 350px) {
  .menu-left,
  .menu-right {
    transform: scale(0.8);
  }
}
