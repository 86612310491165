#cover-page {
  background-color: rgb(40, 29, 45);
  display: block;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
  padding: 0;
}

#cover-page .page-container {
  padding: 1px;
}

#cover-page.media {
  padding: 0;
  background-color: rgb(40, 29, 45);
  display: block;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

#cover-page .rate-overlay,
.profile-container .rate-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(41, 30, 46, 0.75);
  z-index: 50;
}

#cover-page .rate-overlay.share {
  z-index: 4;
}

#cover-page .page-container .hero {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 850px;
  position: relative;
  display: flex;
}

#cover-page .page-container .hero .hero-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(40, 29, 45, 0.2);
  color: white;
  z-index: 1;
}

#cover-page .page-container .hero .hero-contents.cover {
  padding: 0;
}

#cover-page .page-container .hero .hero-contents .splash-contents {
  width: 100%;
  margin-top: 10vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
#cover-page .page-container .hero .hero-contents .back-button,
.media-player .back-button,
#masterclass-container .back-button {
  height: 4.68rem;
  width: 4.68rem;
  background-color: rgba(221, 206, 219, 0.3);
  border-radius: 100%;
  position: fixed;
  left: 2.5rem;
  top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.back-button.player {
  background-color: rgba(221, 206, 219, 0.8);
}

#cover-page .page-container .hero .hero-contents .back-button .return-arrow {
  font-size: 2.18rem;
  align-self: center;
  user-select: none;
}

#cover-page .hero .hero-contents .title-cont {
  width: 60%;
  /* margin-top: 8.12rem; */
  text-align: center;
  /* height: 11.87rem; */
}

#cover-page .hero .hero-contents .title-cont h1 {
  font-family: "futura-pt-demi";
  font-size: 5rem;
  line-height: 5rem;
  margin-bottom: 0;
}

#cover-page .hero .hero-contents h3 {
  width: 60%;
  text-align: center;
  margin: 0;
  font-family: "futura-pt-book";
  font-size: 25px;
}

#cover-page .hero .hero-contents h3 .ta-html {
  margin: 25px 0;
}

#cover-page .hero .hero-contents h3 .ta-html p {
  margin: 0;
  font-weight: 400;
  line-height: 36px;
}

#cover-page .hero .hero-contents .tag-line {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 1.25rem;
  align-items: center;
  background: rgba(40, 29, 45, 0.8);
  width: 26rem;
  height: 2.87rem;
  border-radius: 1.48rem;
  font-size: 1.25rem;
}

#cover-page .hero .hero-contents .tag-line .type {
  text-transform: uppercase;
  font-family: "futura-pt-medium";
}

#cover-page .hero .hero-contents .tag-line .icon {
  height: 25px;
}

#cover-page .hero .hero-contents .tag-line .icon.shield,
#hero-button.take-action .icon.volume {
  vertical-align: bottom;
  transition: none;
}

#hero-button.take-action .icon.volume {
  margin-left: 20px;
  margin-bottom: 3px;
}

#cover-page .hero .hero-contents .cta-block {
  width: 32%;
  display: flex;
  flex-direction: row;
}

/* #cover-page .hero .hero-contents .cta-block.service {
  padding-bottom: 200px;
} */

#cover-page .hero .hero-contents .cta-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

#cover-page .hero .hero-contents .cta-block .chapters-icon {
  margin: 10px 0 0 10px;
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
}

#cover-page .hero .hero-contents .cta-block .chapters-icon.open {
  color: #ea2364;
}

#cover-page .hero .hero-contents .progress-container {
  height: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

#cover-page .hero .hero-contents .progress-container .progress-bar {
  background-color: #ea2364;
  height: 6px;
  border-radius: 3px;
  width: 0%;
}

#cover-page .hero .hero-contents .action-line {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#cover-page .hero .hero-contents .action-line .icon.action {
  height: 50px;
  margin: 0 30px;
  cursor: pointer;
}

.rate-overlay.share .share-container {
  background: white;
  width: 560px;
  height: 308px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  border-radius: 25px;
}

.rate-overlay.share.reminder .share-container {
  height: auto;
}

.rate-overlay.share .share-container .share-heading,
.rate-overlay.share .share-container .share-body {
  height: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rate-overlay.share .share-container .reminder_body .title.repeat,
.rate-overlay.share .share-container .reminder_body .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rate-overlay.share .share-container .reminder_body {
  height: 100px;
  display: flex;
  flex-direction: column;
}

.rate-overlay.share .share-container .reminder_body.repeat,
.rate-overlay.share .share-container .reminder_body.buttons {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* #cover-page .hero-contents .action-line .rate-overlay.share .share-container .share-heading {
  } */

.rate-overlay.share .share-container .share-body,
.rate-overlay.share .share-container .reminder_body {
  padding: 40px;
  border-top: 2px solid rgba(100, 89, 107, 0.1);
}

.rate-overlay.share .share-container .reminder_body {
  padding: 20px;
}

.rate-overlay.share .share-container .share-heading div {
  color: #281d2d;
  padding: 40px;
  font-family: "futura-pt-medium";
  font-size: 17.5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.rate-overlay.share .share-container .share-heading .icon {
  height: 40px;
  color: #64596b;
  padding: 30px;
  cursor: pointer;
}

.rate-overlay.share.reminder .share-container .share-heading .icon {
  padding: 30px 20px 30px 30px;
}

.rate-overlay.share .share-container .share-body .title,
.rate-overlay.share .share-container .reminder_body .title {
  color: #281d2d;
  font-family: "futura-pt-demi";
  font-size: 22.5px;
  user-select: none;
}

.reminder_body .title {
  text-align: left;
}

.rate-overlay.share .share-container .reminder_body .week {
  align-self: center;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.reminder_body .week .day {
  background-color: #d4ccd9;
  margin: 3px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  color: #fff;
  font-family: "futura-pt-medium";
  font-size: 25px;
  user-select: none;
}

.reminder_body .week .day.selected {
  background-color: #e92263;
}

.rate-overlay.share .share-container .share-body .copied {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #281d2d;
  color: #fff;
  width: 110px;
  height: 30px;
  border-radius: 15px;
  font-family: "futura-pt-medium";
  font-size: 17.5px;
  visibility: hidden;
}

.rate-overlay.share .share-container .share-body .copied.visible {
  visibility: visible;
}

.rate-overlay.share .share-container .share-body .copied .icon {
  height: 20px;
  margin: 0;
}

.rate-overlay.share .share-container .share-body .url {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #625a6a;
  width: 100%;
  height: 60px;
  border-radius: 25px;
  background-color: rgba(212, 210, 213, 0.3);
  font-family: "futura-pt-book";
  font-size: 20px;
  padding: 0 25px;
  margin-top: 10px;
}

.rate-overlay.share .share-container .share-body .url .url-address {
  width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rate-overlay.share .share-container .share-body .url .icon {
  height: 22px;
  color: #281d2d;
  cursor: pointer;
}
#cover-page .hero .hero-contents .action-line > div:not(.rate-overlay) {
  position: relative;
}

#cover-page .hero .hero-contents .action-line > div > div {
  text-align: center;
  visibility: hidden;
  height: 35px;
  font-family: "futura-pt-demi";
  font-size: 25px;
  position: absolute;
  z-index: 10;
  text-wrap: nowrap;
  width: fit-content;
  left: 50%;
  transform: translate(-50%);
}

#cover-page .hero .hero-contents .action-line .icon:hover + div {
  visibility: visible;
}

#cover-page .hero .hero-contents .action-line .rate-block {
  position: relative;
  z-index: 53;
}

#cover-page .hero .hero-contents .action-line .rate-block .rate-btns {
  visibility: visible;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  top: -100px;
  left: 60px;
  background-color: #625a6a;
  height: 90px;
  width: 230px;
  border-radius: 46px;
}

#cover-page .hero .hero-contents .action-line .rate-block .rate-btns .icon {
  height: 80px;
  width: 80px;
  background-color: rgba(40, 29, 45, 0.5);
  border-radius: 50%;
  border: none;
  padding: 15px;
  box-sizing: border-box;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 5px 0;
}

#cover-page .hero .hero-contents button#hero-button {
  width: 100%;
  height: 70px;
  border-radius: 35px;
  font-size: 25px;
}

#cover-page .hero .hero-contents button#hero-button:hover {
  color: #fff;
  background-color: rgba(233, 34, 99, 0.8);
  border: 1px solid rgba(233, 34, 99, 0.8);
}

#cover-page .hero .hero-contents .cta-container .progress {
  width: 100%;
}

#cover-page .hero .hero-contents button#hero-button.take-action {
  margin-bottom: 2vh;
}

#cover-page .hero .hero-contents button#hero-button.take-action.learn {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  outline: none;
  background-color: rgba(100, 89, 107, 0.5);
  color: #fff;
  border: none;
}

#cover-page .hero .hero-contents button#hero-button.take-action.learn:hover {
  background-color: #fff;
  color: #64596b;
}

#cover-page .page-container .hero .fade {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-image: linear-gradient(
    to top,
    rgba(40, 29, 45, 1) 0%,
    rgba(40, 29, 45, 0)
  );
}

#cover-page .page-container .author {
  display: flex;
  flex-direction: row;
  padding: 80px 80px 0 80px;
  column-gap: 48px;
}

#cover-page .page-container .author.prog {
  padding-bottom: 0px;
  padding-top: 0px;
}

#cover-page .page-container .author .author-about {
  height: 480px;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#cover-page .page-container .author .author-about .author-img {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  height: 480px;
  min-width: 40%;
}

#cover-page .page-container .author .author-about .author-bio {
  margin-left: 35px;
  color: white;
  position: relative;
}

#cover-page .page-container .author .author-about .author-bio h3 {
  font-size: 1.5rem;
  margin: 0;
}

#cover-page .page-container .author .author-about .author-bio p {
  font-family: "futura-pt-book";
  font-size: 1.25rem;
  line-height: 2.2rem;
  color: #ddcedb;
  margin: 0;
}

#cover-page .page-container .author .author-about .author-bio p:nth-child(3) {
  margin: 40px 0 50px 0;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#cover-page .page-container .author .author-about .author-bio p:last-child {
  color: #fff;
  font-family: "futura-pt-demi";
}

.author-bio p a {
  text-decoration: none;
  color: inherit;
}

#cover-page .page-container .author .library {
  height: 480px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #64596b;
  border-radius: 12px;
  color: white;
}

#cover-page .page-container .author .library.prog {
  background-color: #372c3d;
}

#cover-page .page-container .author .library.prog .prog-cont {
  width: 100%;
  font-size: 20px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

#cover-page .page-container .author .library.prog .prog-cont p {
  color: #ddcedb;
}

#cover-page .page-container .author .library.prog .prog-cont .progress-charts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .progress-graph {
    width: 180px;
    margin: 0 5px;
    .progress-circle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 180px;
      border-radius: 50%;
      border: solid 1.5px #64596b;
      /* text-align: center; */
      color: #41c0d3;
      .circle-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 45px;
        font-family: "futura-pt-demi";
        .sml {
          line-height: 18px;
          font-size: 22.5px;
          font-family: "futura-pt-medium";
          margin-top: 0;
          letter-spacing: 2px;
        }
      }
    }
    .progress-text {
      margin: 15px 0;
    }
  }
}

#cover-page .page-container .author .library h2,
#cover-page .page-container .related h1 {
  font-family: "futura-pt-demi";
  font-size: 40px;
  margin: 35px 0 0 0;
}

#cover-page .page-container .author .library .scrollable {
  overflow-y: scroll;
  margin: 35px 10px 35px 0;
}

#cover-page .page-container .author .library .scrollable::-webkit-scrollbar {
  width: 4px;
}

#cover-page
  .page-container
  .author
  .library
  .scrollable::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

#cover-page
  .page-container
  .author
  .library
  .scrollable::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2.5px;
  background: #291e2e;
}

#cover-page .page-container .author .library .content {
  margin: 0 40px 10px 40px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

#cover-page .page-container .author .library .content p:last-child {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#cover-page .page-container .author .library .content.prog {
  padding: 8px 25px;
  margin: 0 15px;
}

#cover-page .page-container .author .library .content.prog:hover {
  background-color: #64596b;
  border-radius: 12px;
}

#cover-page .page-container .author .library .content .content-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 142px;
  min-width: 30%;
  margin-right: 30px;
  .prog-icon {
    margin: 10px;
  }
}

#cover-page
  .page-container
  .author
  .library
  .content
  .text
  p:first-child.text-title-notime {
  margin-bottom: 20px;
}

#cover-page .page-container .author .library .content .text p:first-child {
  font-family: "futura-pt-demi";
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 1.25rem 0 0 0;
  font-weight: bold;
}

#cover-page .page-container .author .library .content .text p:nth-child(2) {
  font-family: "futura-pt-book";
  font-size: 0.95rem;
  margin: 0.5rem 0 0.25rem 0;
}

#cover-page .page-container .author .library .content .text p:last-child {
  font-family: "futura-pt-book";
  font-size: 1.25rem;
  margin: 0;
  color: #ddcedb;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#cover-page .page-container .related {
  padding: 0 80px;
  color: white;
  margin: 35px 0 200px 0;
}

#cover-page .page-container .negative-marg.related {
  margin-top: -7em;
}

.reminder_body .switch {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 50px;
  background-color: #d4ccd9;
  border-radius: 40px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.reminder_body .switch.on {
  background-color: #e92263;
}

.reminder_body .switch.off .slider {
  transform: translateX(0);
}

.reminder_body .switch.on .slider {
  transform: translateX(150px);
}

.reminder_body .slider {
  position: absolute;
  margin: 3px;
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.reminder_body .label {
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  line-height: 50px;
  color: white;
}

.reminder_body.buttons .clear {
  color: #281d2d;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.reminder_body.buttons button.submit {
  width: 200px;
  height: 50px;
  justify-content: center;
  margin-bottom: 0;
  user-select: none;
}

#cover-page a {
  color: #fff;
}

@media (max-width: 1050px) {
  #cover-page .hero .hero-contents .action-line {
    padding-bottom: 20px;
  }
  #cover-page .hero .hero-contents .cta-block {
    width: 50%;
  }
  #cover-page .page-container .author {
    flex-direction: column;
    column-gap: none;
    row-gap: 48px;
  }

  #cover-page .page-container .author .author-about,
  #cover-page .page-container .author .library {
    width: 100%;
  }
}

@media (max-width: 1050px) {
  #cover-page .hero .hero-contents .cta-block {
    width: 60%;
  }
}

@media (max-width: 800px) {
  #cover-page .hero .hero-contents .action-line {
    padding-bottom: 30px;
  }
}

@media (max-width: 700px) {
  #cover-page .hero .hero-contents .title-cont {
    width: 80%;
  }

  #cover-page .hero .hero-contents .title-cont h1 {
    font-size: 4rem;
    line-height: 4rem;
  }

  #cover-page .hero .hero-contents .tag-line {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0rem 1.25rem;
    align-items: center;
    background: rgba(40, 29, 45, 0.8);
    width: 20rem;
    border-radius: 1.48rem;
    font-size: 1rem;
  }

  #cover-page .hero .hero-contents h3 {
    width: 80%;
    font-size: 18px;
  }

  #cover-page .hero .hero-contents .action-line {
    width: 80%;
  }

  #cover-page .hero .hero-contents .action-line .icon.action {
    height: 35px;
    margin: 0px 25px;
  }

  #cover-page .hero .hero-contents .action-line > div > div {
    height: 25px;
    font-size: 20px;
  }

  #cover-page .hero .hero-contents button#hero-button {
    width: 100%;
    height: 55px;
    border-radius: 31px;
    font-size: 20px;
    padding: 10px 0;
  }
}

@media (max-width: 600px) {
  #cover-page .hero .hero-contents .action-line {
    padding-bottom: 0;
  }

  #cover-page .page-container .author .author-about {
    height: auto;
    flex-direction: column;
  }
  #cover-page .page-container .author .author-about .author-img {
    margin-bottom: 15px;
    height: 300px;
    width: 250px;
  }
  #cover-page .page-container .author .author-about .author-bio {
    margin-left: 0px;
  }
  #cover-page .page-container .author .library .content {
    margin: 0px 40px 40px 40px;
    flex-direction: column;
  }

  #cover-page .page-container .author .library .content .content-img {
    margin-right: 0px;
  }
  #cover-page .page-container .author .library .content .text p:first-child {
    margin: 0.5rem 0 0 0;
  }
  #cover-page .page-container .author .library .content {
    margin: 0px 10px 20px 22px;
  }

  .rate-overlay.share .share-container {
    width: 85%;
  }

  .rate-overlay.share .share-container .share-body .url {
    width: 90%;
  }

  .rate-overlay.share .share-container .share-body .url .url-address {
    width: 80%;
  }
}

@media (max-width: 550px) {
  #cover-page .page-container .hero {
    min-height: 800px;
  }

  #cover-page .page-container .hero .hero-contents .back-button,
  .media-player .back-button,
  #masterclass-container .back-button {
    height: 3.5rem;
    width: 3.5rem;
  }

  #cover-page .page-container .hero .hero-contents .back-button .return-arrow {
    font-size: 1.5rem;
  }

  #cover-page #progress_footer_container ul {
    width: 100%;
  }

  #cover-page .page-container .related {
    padding: 0 20px;
    margin: 35px 0 100px 0;
  }
  #cover-page
    .page-container
    .author
    .library.prog
    .prog-cont
    .progress-charts
    .progress-graph
    .progress-circle {
    height: 140px;
    width: 140px;
  }

  #cover-page
    .page-container
    .author
    .library.prog
    .prog-cont
    .progress-charts
    .progress-graph {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #cover-page
    .page-container
    .author
    .library.prog
    .prog-cont
    .progress-charts
    .progress-graph
    .progress-circle
    .circle-text {
    transform: scale(0.8);
  }

  #cover-page
    .page-container
    .author
    .library.prog
    .prog-cont
    .progress-charts
    .progress-graph
    .progress-text {
    font-size: 16px;
    margin: 0 0 10px 0;
  }
}

@media (max-width: 500px) {
  #cover-page .page-container .author {
    padding: 40px 40px 0 40px;
    column-gap: 30px;
  }
  #cover-page .page-container .author .author-about .author-bio h3 {
    font-size: 20px;
  }

  #cover-page .page-container .author .author-about .author-bio p {
    font-size: 14px;
    line-height: 18px;
  }

  #cover-page .page-container .author .author-about .author-bio p:nth-child(3) {
    margin: 10px 0 15px 0;
  }

  #cover-page .page-container .author .library h2,
  #cover-page .page-container .related h1 {
    font-size: 25px;
  }

  #cover-page .page-container .author .library .content .text p:first-child {
    font-size: 18px;
  }
  #cover-page .page-container .author .library .content .text p:nth-child(2) {
    font-size: 12px;
    margin: 0.25rem 0 0 0;
  }

  #cover-page .page-container .author .library .content .text p:last-child {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  #cover-page .page-container .hero {
    min-height: 600px;
  }

  #cover-page .page-container .hero .hero-contents .back-button,
  .media-player .back-button,
  #masterclass-container .back-button {
    left: 0.5rem;
    top: 0.5rem;
  }
  #cover-page .hero .hero-contents .title-cont h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
  #cover-page .hero .hero-contents h3 {
    font-size: 16px;
  }

  #cover-page .page-container .author .library h2,
  #cover-page .page-container .related h1 {
    font-size: 35px;
  }

  #cover-page .hero .hero-contents .cta-block {
    width: 80%;
  }

  #cover-page .hero .hero-contents .action-line {
    padding-bottom: 20px;
  }

  #cover-page .hero .hero-contents button#hero-button {
    font-size: 16px;
  }
  #cover-page .hero .hero-contents .action-line .rate-block .rate-btns .icon {
    height: 50px;
    width: 50px;
    padding: 10px;
  }
  #cover-page .hero .hero-contents .action-line .rate-block .rate-btns {
    top: -68px;
    left: -35px;
    height: 60px;
    width: 154px;
    border-radius: 32px;
  }

  .rate-overlay.share .share-container .share-heading div {
    padding: 40px 0 40px 40px;
  }

  .rate-overlay.share.reminder .share-container .share-heading .icon {
    padding: 30px 20px 30px 0px;
  }
}

@media (max-width: 350px) {
  #cover-page .hero .hero-contents .title-cont h1 {
    font-size: 2rem;
    line-height: 2rem;
  }

  #cover-page .hero .hero-contents h3 {
    font-size: 1rem;
  }
  #cover-page .hero .hero-contents .action-line .icon.action {
    margin: 0px 20px;
  }
  #cover-page .hero .hero-contents .action-line > div > div {
    height: 20px;
    font-size: 16px;
  }
  #cover-page .page-container .author {
    padding: 40px 20px 0 20px;
  }

  .rate-overlay.share .share-container .share-heading,
  .rate-overlay.share .share-container .share-body {
    height: 80px;
  }

  .rate-overlay.share .share-container .share-heading div {
    padding: 32px 0 32px 32px;
    font-size: 15px;
  }

  .rate-overlay.share .share-container .share-heading .icon {
    height: 30px;
    padding: 28px;
  }

  .rate-overlay.share .share-container .share-body,
  .rate-overlay.share .share-container .reminder_body {
    padding: 28px;
  }

  .rate-overlay.share .share-container .share-body .title,
  .rate-overlay.share .share-container .reminder_body .title {
    font-size: 20px;
  }

  .rate-overlay.share .share-container .share-body .url {
    width: 80%;
  }

  .rate-overlay.share .share-container .share-body .url {
    font-size: 15px;
  }

  .question_container .clock .hours,
  .question_container .clock .minutes,
  .reminder_body .clock .hours,
  .reminder_body .clock .minutes {
    border-radius: 21px;
    height: 40px;
    min-width: 80px;
  }

  .question_container .number_controls .up_btn,
  .question_container .number_controls .down_btn,
  .reminder_body .number_controls .up_btn,
  .reminder_body .number_controls .down_btn {
    height: 16px;
    width: 16px;
  }

  .reminder_body .week .day {
    margin: 1px;
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-size: 20px;
  }

  .reminder_body .switch {
    width: 140px;
    height: 40px;
    border-radius: 21px;
  }

  .reminder_body .switch {
    width: 140px;
    height: 40px;
    border-radius: 21px;
  }
  .reminder_body .slider {
    margin: 2px;
    width: 36px;
    height: 36px;
  }

  .reminder_body .label {
    font-size: 12px;
    line-height: 40px;
  }
  .reminder_body .switch.on .slider {
    transform: translateX(100px);
  }

  .reminder_body.buttons button.submit {
    width: 140px;
    height: 40px;
  }

  button.darkButton {
    font-size: 12px;
  }

  .reminder_body.buttons .clear {
    font-size: 14px;
  }
  .rate-overlay.share.reminder .share-container .share-heading .icon {
    padding: 27px 20px 30px 0px;
  }

  #cover-page .page-container .author .library h2,
  #cover-page .page-container .related h1 {
    font-size: 25px;
  }

  #cover-page .hero .hero-contents .cta-block .chapters-icon {
    height: 30px;
    width: 30px;
  }
}
