.recipe {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 80px;
  column-gap: 50px;
}

.recipe .container {
  background-color: #372c3d;
  color: #ddceda;
  padding: 40px;
  border-radius: 12px;
}

.recipe .ingredients.container,
.recipe .method.container {
  display: flex;
  flex-direction: column;
}

.recipe .ingredients.container .body,
.recipe .method.container .body {
  font-family: "futura-pt-book";
  font-size: 20px;
  height: 405px;
  position: relative;
  border-radius: 12px;
}

.recipe .ingredients.container .body {
  border: 1px solid #64596b;
  padding: 5px 5px 0 0;
}

.recipe .ingredients.container .body .fade,
.recipe .method.container .body .fade {
  position: absolute;
  height: 100px;
  width: 100%;
  bottom: 0;
  background-image: linear-gradient(
    to top,
    rgba(55, 44, 61, 1) 0%,
    rgba(55, 44, 61, 0)
  );
  user-select: none;
}

.recipe .ingredients.container .body .fade {
  border-radius: 0 0 12px 12px;
}

.recipe .container .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.recipe .container .title h3 {
  color: white;
  font-family: "futura-pt-demi";
  font-size: 40px;
}

.recipe .container .title h4 {
  font-family: "futura-pt-medium";
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 400;
  letter-spacing: 1px;
}

.recipe .container .body .list {
  height: 403px;
  overflow-y: auto;
}

.recipe .container .body .list::-webkit-scrollbar {
  width: 4px;
}

.recipe .container .body .list::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.recipe .container .body .list::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2px;
  background: #291e2e;
}

.recipe .ingredients.container .body .list .item {
  padding-left: 25px;
}

.recipe .ingredients.container .body .list .item:first-child {
  padding-top: 5px;
}

.recipe .container .body .list .item {
  min-height: 50px;
  border-bottom: 1px solid #64596b;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recipe .container .body .list .item .step {
  padding-right: 4px;
}

.recipe .container .body .list .item:last-child {
  border-bottom: none;
}

.recipe .container .body .list .item .check {
  height: 30px;
  width: 30px;
  color: #64596b;
  margin-right: 20px;
}

.recipe .container .body .list .item .number {
  align-self: flex-start;
  color: #41c0d3;
  font-family: "futura-pt-demi";
  font-size: 30px;
  margin-right: 35px;
}

.recipe .method.container .body .list .item {
  padding: 37px 0 37px 0;
}

.recipe .method.container .body .list .item:first-child {
  padding: 0 0 37px 0;
}

.recipe .nutrition.container .body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  gap: 20px;
  margin-top: 30px;
}

.recipe .container .body .nutri-info {
  height: 143px;
  width: 143px;
  border-radius: 100%;
  border: solid 1px #64596b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recipe .container .body .nutri-info h4 {
  font-family: "futura-pt-medium";
  font-size: 20px;
  margin: 0;
  font-weight: 400;
  letter-spacing: 1px;
}

.recipe .container .body .nutri-info h4.kcal {
  color: #fcc954;
}

.recipe .container .body .nutri-info h4.carbs {
  color: #41c0d3;
}

.recipe .container .body .nutri-info h4.fat {
  color: #ef545a;
}

.recipe .container .body .nutri-info h4.protein {
  color: #62bf92;
}

.recipe .container .body .nutri-info div {
  font-size: 30px;
  color: white;
  font-family: "futura-pt-demi";
}
@media (max-width: 1490px) {
  .recipe .container .body .nutri-info {
    height: 120px;
    width: 120px;
  }
}
@media (max-width: 1370px) {
  .recipe .container .title h4 {
    font-size: 16px;
  }
}

@media (max-width: 1300px) {
  .recipe .container .title h4 {
    font-size: 16px;
  }

  .ingredients.container {
    grid-area: ingredients;
  }
  .method.container {
    grid-area: method;
  }
  .nutrition.container {
    grid-area: nutrition;
  }

  .recipe .nutrition.container {
    height: 210px;
    overflow-y: hidden;
  }

  .recipe .nutrition.container h3,
  .recipe .ingredients.container h3 {
    margin-bottom: 0;
  }

  .recipe .ingredients.container .title {
    flex-direction: column;
  }

  .recipe .ingredients.container .title h4 {
    align-self: flex-end;
    margin-bottom: 10px;
  }

  .recipe {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas:
      "nutrition nutrition"
      "ingredients method";
    row-gap: 50px;
  }
}

@media (max-width: 1090px) {
  .recipe {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "nutrition nutrition"
      "ingredients method";
    row-gap: 50px;
  }
}

@media (max-width: 950px) {
  .recipe .container .body .nutri-info {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 800px) {
  .recipe .container .body .nutri-info {
    height: 100px;
    width: 100px;
  }

  .recipe .ingredients.container .title {
    flex-direction: row;
  }

  .recipe .ingredients.container .title h3 {
    margin-bottom: 40px;
  }

  .recipe .ingredients.container .title h4 {
    align-self: baseline;
  }

  .recipe {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "nutrition"
      "ingredients"
      "method";
    row-gap: 50px;
  }
}

@media (max-width: 730px) {
  .recipe .nutrition.container {
    height: 300px;
  }
}

@media (max-width: 500px) {
  .recipe .nutrition.container {
    overflow-y: auto;
  }

  .recipe {
    padding: 0 40px;
    column-gap: 25px;
  }

  .recipe .container {
    padding: 14px;
  }

  .recipe .ingredients.container .body,
  .recipe .method.container .body {
    font-size: 14px;
  }

  .recipe .container .title h3 {
    font-size: 20px;
  }

  .recipe .container .title h4 {
    font-size: 12px;
  }

  .recipe .ingredients.container .body .list .item {
    padding-left: 10px;
  }

  .recipe .container .body .list .item {
    min-height: 40px;
  }

  .recipe .container .body .list .item .check {
    height: 15px;
    width: 15px;
    margin-right: 14px;
  }

  .recipe .container .body .list .item .number {
    font-size: 16px;
    margin-right: 15px;
  }

  .recipe .method.container .body .list .item {
    padding: 20px 0 20px 0;
  }

  .recipe .method.container .body .list .item:first-child {
    padding: 0 0 20px 0;
  }

  .recipe .container .body .nutri-info h4 {
    font-size: 12px;
  }

  .recipe .container .body .nutri-info div {
    font-size: 20px;
  }
}

@media (max-width: 350px) {
  .recipe {
    padding: 0 20px;
    column-gap: 25px;
  }
}
