.activitySlider {
    margin: 50px 20px;
    position:relative;
    display:block;
}
.activitySlider .noUi-handle {
    background: url('./images/running_man.svg') no-repeat;
    background-size:contain;
    width:60px;
    height: 60px;
    background-color:transparent;
    border:none;
    margin-top:-20px;
    box-shadow: none;
    outline:none;
    cursor:pointer;
    /*left:33px;*/
    right: -33px;
}
.activitySlider .noUi-connects {
    cursor:pointer;
}
.activitySlider .noUi-handle::before, .activitySlider .noUi-handle::after {
    display:none;
}
.activitySlider .noUi-connect {
	background-color: #E92263;
}
.activitySlider .noUi-horizontal {
    height:6px;
    margin: 80px 0;
}
.activitySlider .noUi-target {
    background-color: #80738a;
    box-shadow:none;
    border:none;
}
.activitySlider .leftLabel, .activitySlider .rightLabel {
    position:absolute;
    top:-60px;
    text-transform: uppercase;
    color: #E92263;
    font-family: 'futura-pt-demi';
    font-weight: 600;
    font-size: 12px;    
}
.activitySlider .leftLabel {
    left:-20px;
}
.activitySlider .rightLabel {
    right: -20px;
}
.activitySlider .valueHandle {
    position:absolute;
    top:50px;
    margin-left:-50px;
    width:100px;
    transition: left 0.3s;
    color:#80738a;
    text-transform:uppercase;
    font-family: 'futura-pt-demi';
    font-weight: 600;
    font-size: 14px;      
}