button.pinkButton1 {
  padding: 0px 30px;
  height: 40px;
  text-align: center;
  border-radius: 40px;
  font-family: "futura-pt-demi", sans-serif;
  cursor: pointer;
  border: none;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 20px;
  transition: all 0.5s ease;
  outline: none;
  background-color: #e92364;
  color: #fff;
}

button.pinkButton1:disabled,
button.pinkButton1:disabled:hover {
  background-color: #64596b33;
  color: #ffffff80;
  border: none;
  cursor: not-allowed;
}

button.pinkButton1.wide {
  width: 100%;
}

button.pinkButton1.marginTop30 {
  margin-top: 30px;
}
button.pinkButton1 .svg-inline--fa {
  margin-top: 0;
}

button.pinkButton1:hover {
  border: 1px solid #e92364;
  background-color: #fff;
  color: #e92364;
}
button.pinkButton1 .icon {
  margin-left: 5px;
  margin-right: 0;
  margin-top: 3px;
  transition: all 0.5s ease;
}
button.pinkButton1:hover .icon {
  margin-left: 10px;
  margin-right: -5px;
}
