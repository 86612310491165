#main_container.signin {
  overflow: hidden;
}

.register #right_container,
.signin #right_container {
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.signin.register #right_container,
.profile.register #right_container {
  text-align: left;
}
.signin.register #right_container .question_block,
.profile.register #right_container .question_block {
  width: 95%;
}

.register.profile #left_container {
  background-image: url("../images/register.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

#right_container.signin .profile-fields .question_block.full_width {
  width: 90%;
}

.signin.reset.register #right_container .question_block {
  width: 95%;
}

.signin.register #right_container .answer_block,
.profile.register #right_container .answer_block {
  margin-top: 5px;
}

#right_container .frame {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 650px;
  -webkit-box-shadow: -1px 1px 20px 30px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: -1px 1px 20px 30px rgba(0, 0, 0, 0.06);
  box-shadow: -1px 1px 20px 30px rgba(0, 0, 0, 0.06);
}

#right_container .frame .logo {
  border-radius: 50%;
  background-image: -moz-linear-gradient(
    145deg,
    rgb(232, 35, 99) 0%,
    rgb(168, 23, 88) 100%
  );
  background-image: -webkit-linear-gradient(
    145deg,
    rgb(232, 35, 99) 0%,
    rgb(168, 23, 88) 100%
  );
  background-image: linear-gradient(
    145deg,
    rgb(232, 35, 99) 0%,
    rgb(168, 23, 88) 100%
  );
  width: 75px;
  height: 75px;
  z-index: 21;
  margin-bottom: 25px;
}

#right_container .frame .logo img {
  width: 40px;
  height: 40px;
  margin-top: 15px;
}

#right_container .frame h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

#right_container .frame p {
  font-family: "futura-pt-medium";
  font-size: 17px;
  color: #7e658c;
  width: 320px;
  margin-bottom: 25px;
}

#right_container .frame form {
  display: flex;
  flex-direction: column;
  width: 315px;
}

#right_container .frame .login_error {
  color: #ef3f23;
  font-size: 14px;
  margin-bottom: 15px;
}

#right_container .frame .login_error.success {
  color: #62bf92;
}

#right_container .frame .login_error .icon {
  margin-right: 4px;
}

form .password_container {
  position: relative;
}

form .password_container .icon {
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;
}

#right_container .frame input[type="text"].code_input,
#right_container .frame input[type="email"].code_input,
#right_container .frame input[type="password"].code_input {
  font-family: "futura-pt-medium", sans-serif;
  width: 275px;
  height: 40px;
  margin-bottom: 10px;
  color: #291e2e;
}

.signin #right_container .frame input#password.code_input,
.signin #right_container .frame input#confirm_password.code_input {
  width: 315px;
}

.signin #right_container .frame input#password.code_input {
  width: 275px;
}

#right_container .frame .signup {
  color: #80738a;
  cursor: pointer;
  transition: none;
  width: 22px;
}

#right_container .frame input[type="text"].code_input::placeholder,
#right_container .frame input[type="email"].code_input::placeholder,
#right_container .frame input[type="password"].code_input::placeholder {
  color: #7e658c;
  font-family: "futura-pt-medium", sans-serif;
  font-size: 17px;
  width: 275px;
}

#right_container .frame input[type="password"].code_input.passcode {
  font-family: "futura-pt-medium", sans-serif;
  width: 100%;
  height: 60px;
  font-size: 34px;
  text-align: center;
  color: #291e2e;
  border-radius: 31px;
}

#right_container
  .frame
  input[type="password"].code_input.passcode::placeholder {
  font-size: 17px;
  transform: translateY(-6px);
}

#right_container .frame .small_text {
  color: #7e658c;
  font-size: 14px;
  width: 300px;
  text-align: left;
  margin-bottom: 15px;
}

#right_container .frame .tandc {
  width: 415px;
  height: 230px;
  margin-bottom: 30px;
  border: solid 2px #efebf0;
  border-radius: 5px;
  padding: 25px 15px 18px 25px;
}

#right_container .frame .tandc .tandc_container {
  overflow-y: scroll;
  height: 210px;
}

#right_container .frame .tandc_container::-webkit-scrollbar {
  width: 4px;
}

#right_container .frame .tandc_container::-webkit-scrollbar-track {
  border-radius: 2px;
  width: 4px;
  background: #dfd8e2;
}

#right_container .frame .tandc_container::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2px;
  background: #291e2e;
}

#right_container .frame .tandc_container p {
  color: #291e2e;
  font-family: "futura-pt-book";
  font-size: 14px;
  text-align: left;
  width: 385px;
}

#right_container .frame button {
  width: 315px;
  height: 40px;
  margin-bottom: 0;
}

.signin .frame button {
  width: inherit;
  margin-top: inherit;
  font-size: inherit;
  padding: inherit;
}

#right_container .frame .error_container {
  margin-top: -50px;
  margin-bottom: 20px;
}

#right_container .frame .error_container.passcode {
  margin: 0;
}

#right_container .frame p.reset {
  margin: 0;
  cursor: pointer;
  color: #e92263;
  font-family: "futura-pt-demi";
  font-size: 16px;
}

#right_container .frame button.grey_theme {
  background-color: #f6f5f7;
  color: #291e2e;
  border: none;
  margin-top: 10px;
  box-shadow: none;
  position: relative;
}

#right_container .frame button:hover.grey_theme,
#right_container .frame button:focus.grey_theme {
  background-color: #f6f5f7;
  color: #e92263;
  border: none;
}

button#continue:hover,
button#continue:focus {
  background-color: #f6f5f7;
  color: #e92263;
  border: 1px solid #e92263;
}

button.grey_theme img.icon.signup {
  position: absolute;
  left: 15px;
}

.signin button:hover.grey_theme img.icon.signup,
.signin button:focus.grey_theme img.icon.signup,
.register button:hover.grey_theme img.icon.signup,
.register button:focus.grey_theme img.icon.signup {
  margin-left: 5px;
}

#right_container .frame .tandc .dd.icon {
  cursor: pointer;
}

#right_container .frame .acceptance_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 330px;
}

#right_container .frame .acceptance_container button {
  width: 280px;
}

#right_container .frame .passcode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  margin-bottom: 25px;
}

#right_container .frame #passcode.wrong {
  color: #ef3f23;
}

#right_container .frame #passcode.confirmed {
  color: #60bf92;
}

#right_container .frame .acceptance_container button.declined,
#right_container .frame .acceptance_container button.declined:hover,
#right_container .frame .acceptance_container button.declined:focus,
#right_container .frame button.declined,
#right_container .frame button.declined:hover,
#right_container .frame button.declined:focus {
  background-color: #ddcedb;
  border: none;
  box-shadow: none;
  color: white;
  cursor: auto;
}

#right_container .frame .dd.icon.circle-check {
  cursor: pointer;
  padding: 0;
  height: 40px;
  width: 40px;
  margin: 0;
}

#right_container .frame .dd.icon.circle-check.inactive {
  color: #ddcedb;
  cursor: auto;
}

#right_container .frame .dd.icon.circle-check.available {
  color: #949197;
}

#right_container .frame .acceptance_container button.accept_terms {
  cursor: pointer;
  padding: 0;
  height: 42px;
  width: 42px;
  padding: none;
  text-align: center;
  border-radius: 22px;
  font-family: "futura-pt-demi", sans-serif;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: none;
  box-shadow: none;
  transition: all 0.5s ease;
  outline: none;
  background-color: white;
  color: #fff;
}

#right_container .frame .acceptance_container button.accept_terms.ready {
  border: solid 1px #e92263;
}

#right_container .frame .dd.icon.circle-check.available.accepted {
  color: #e92263;
}

#right_container .signin,
.frame a#link {
  font-family: "futura-pt-demi";
  font-size: 16px;
  color: #7e658c;
  margin-bottom: 5px;
}

.frame a#link {
  text-align: left;
  margin: 15px 0;
}

.signin a {
  text-decoration: none;
}

.register.user_profile #left_container {
  background-image: url("../images/user_profile_bg.svg");
}

.register #section_progress {
  width: calc(60% - 60px);
}

.register h1,
.signin h1 {
  font-size: 50px;
  margin-bottom: 30px;
  font-family: "futura-pt-demi";
}

.register #left_container h1,
.signin #left_container h1 {
  height: 80px;
}

.register #left_container .main_text,
.signin #left_container .main_text {
  height: 20vh;
  color: #64596b;
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.register #left_container .secondary_text,
.signin #left_container .secondary_text {
  color: white;
}

.register #left_container .fade,
.signin #left_container .fade {
  width: 50%;
  height: 330px;
  background-image: linear-gradient(
    to top,
    rgba(41, 30, 46, 1) 30%,
    rgba(41, 30, 46, 0)
  );
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.bottom_padding {
  margin-top: 38px;
}

.bottom_padding_small {
  margin-top: 19px;
}

.register p,
.signin p {
  font-size: 18px;
  margin: 0 0 30px 0;
}

.profile-fields {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2%;
}

.profile-fields > .question_block {
  margin-bottom: 20px;
  min-height: 100px;
}

input[type="text"].code_input,
input[type="email"].code_input,
input[type="password"].code_input {
  background-color: #f1f1f1;
  color: #e92263;
  border: none;
  border-radius: 25px;
  padding: 0 20px;
  height: 50px;
  width: 220px;
  font-size: 17px;
  outline: none;
  margin-bottom: 30px;
}

input[type="email"].code_input.invalid,
input[type="password"].code_input.invalid,
input[type="text"].code_input.invalid {
  border: solid 2px red;
}

.profile_options_block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile_options {
  margin-top: 5px;
  border-radius: 25px;
  background-color: #f6f5f7;
  border: none;
  padding: 12px 25px;
  outline: 0;
  box-sizing: border-box;
  color: #a81758;
  font-size: 16px;
  font-family: "futura-pt-medium", sans-serif;
  margin-right: 5px;
  cursor: pointer;
}

.profile_options#active {
  background-color: #a81758;
  color: #fff;
}
.profile-image-upload {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image-container {
  width: 125px;
  border-radius: 65px;
  height: 125px;
  border-radius: 65px;
  overflow: hidden;
}

#preview {
  width: 125px;
}

#status {
  font-family: "futura-pt-medium", sans-serif;
  color: #a81758;
  font-size: 18px;
  display: inline;
  font-weight: 500;
  margin-top: 15px;
}

#file-input {
  margin-top: 5px;
  text-align-last: center;
}

.pic-upload-message {
  width: 300px;
  text-align: center;
  color: #e92263;
}

.profile-padding {
  padding-bottom: 150px;
}

button.profile-delete {
  background-color: #f6f5f7;
  color: #e92263;
  border: none;
}
button:hover.profile-delete,
button:focus.profile-delete {
  color: #f6f5f7;
  background-color: #e92263;
}

.signin p.or {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  font-weight: 600;
}
.register #right_container .frame p.or {
  margin: 15px 0 5px 0;
  font-weight: 600;
}

.signin p a {
  color: #7e658c;
}

.lower_container {
  display: flex;
  justify-content: space-around;
}

.lower_container p {
  margin-left: 20px;
  margin-right: 20px;
}

.settings-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
}

.settings-page #left_container {
  display: none;
}

.settings-page #right_container {
  width: 100%;
  padding-left: 50px;
}

.settings-page ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  columns: 2;
  column-gap: 20px;
}

@media (max-width: 750px) or ((min-width: 1050px) and (max-width: 1300px)) {
  #main_container.register #left_container,
  #main_container.signin #left_container {
    display: none;
  }

  #main_container.register #right_container,
  #main_container.signin #right_container {
    overflow: hidden;
    padding: 0;
  }

  #right_container .frame {
    width: 480px;
    height: 464px;
  }
  #right_container .frame .logo {
    width: 60px;
    height: 60px;
  }

  #right_container .frame .logo img {
    width: 32px;
    height: 32px;
  }

  #right_container .frame h1 {
    font-size: 24px;
  }

  #right_container .frame p,
  #right_container .frame input[type="text"].code_input::placeholder,
  #right_container .frame input[type="email"].code_input::placeholder,
  #right_container .frame input[type="password"].code_input::placeholder {
    font-size: 14px;
  }

  #right_container .frame input[type="text"].code_input,
  #right_container .frame input[type="email"].code_input,
  #right_container .frame input[type="password"].code_input {
    height: 32px;
    font-size: 15px;
  }

  #right_container .frame .small_text {
    font-size: 11px;
  }
  #right_container .frame button {
    height: 32px;
    font-size: 12px;
    padding: 0;
    line-height: 12px;
    text-align: center;
  }

  #right_container .frame p.reset {
    font-size: 13px;
  }

  #right_container .frame .tandc {
    width: 332px;
    height: 184px;
  }

  #right_container .frame .tandc .tandc_container {
    height: 168px;
  }

  #right_container .frame .tandc_container p {
    font-size: 11px;
    width: 308px;
  }
  #right_container .frame .dd.icon.circle-check {
    height: 34px;
    width: 34px;
  }

  #right_container .frame .signup {
    width: 14px;
  }

  form .password_container .icon {
    top: 9px;
    height: 15px;
  }

  .lower_container {
    display: inline;
  }
}

@media (max-width: 800px) {
  .signin.register #right_container .question_block,
  .profile.register #right_container .question_block {
    width: 100%;
  }
  .profile-fields {
    grid-template-columns: 1fr;
  }

  .register .frame h1 {
    margin-top: 0px;
  }

  .register h1 {
    margin-top: 50px;
  }
}
@media (max-width: 500px) {
  .register #section_progress {
    width: calc(80% - 60px);
  }
  #right_container .frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    min-height: 400px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0px;
  }
}
@media (max-width: 375px) {
  .register #right_container {
    width: 100%;
    padding: 0;
  }
  #right_container .frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    min-height: 400px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0px;
  }
  #right_container .frame form,
  #right_container .frame .tandc,
  #right_container .frame p,
  #right_container .frame .small_text,
  #right_container .frame button,
  .signin #right_container .frame input#password.code_input {
    width: 250px;
  }

  #right_container .frame .tandc_container p {
    width: 200px;
  }

  #right_container .frame .acceptance_container,
  #right_container .frame .passcode {
    width: 275px;
  }

  #right_container .frame input[type="text"].code_input,
  #right_container .frame input[type="email"].code_input,
  #right_container .frame input[type="password"].code_input,
  .frame input#company_code.code_input,
  #right_container .frame input[type="password"].code_input.passcode {
    width: 210px;
  }

  .signin #right_container .frame input[type="password"].code_input.passcode {
    width: 250px;
  }

  #right_container .frame input[type="password"].code_input.passcode {
    font-size: 45px;
  }

  .signin
    #right_container
    .frame
    input[type="password"].code_input.passcode::placeholder,
  #right_container
    .frame
    input[type="password"].code_input.passcode::placeholder {
    text-align: left;
  }

  #right_container .frame .dd.icon.circle-check.inactive {
    margin-right: 5px;
  }
}
