.energySlider {
  margin: 80px 0px 40px 0px;
  position: relative;
  display: block;
}
.energySlider .noUi-target {
  box-shadow: none;
  border: none;
  background: linear-gradient(
    0.25turn,
    #ef545a,
    #f69358,
    #fcc954,
    #afc473,
    #62bf92
  );
  cursor: pointer;
}
.energySlider .noUi-connects {
}
.energySlider .noUi-connect {
  background: #ccc;
}
.energySlider .noUi-connects:after {
  content: "";
  background-image: url("./images/gradient_overlay.svg");
  background-position: center center fixed;
  width: 100%;
  height: 80px;
  position: absolute;
  z-index: 99;
  background-size: contain;
}

.energySlider .noUi-handle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: none;
  margin-top: -5px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  background-color: rgb(239, 84, 90);
  -webkit-box-shadow: inset -7px -3px 3px 3px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset -7px -3px 3px 3px rgba(0, 0, 0, 0.08);
  box-shadow: inset -7px -3px 3px 3px rgba(0, 0, 0, 0.08);
}
.energySlider .noUi-handle::before,
.energySlider .noUi-handle::after {
  display: none;
}
.energySlider .leftLabel,
.energySlider .rightLabel {
  position: absolute;
  top: -50px;
  text-transform: uppercase;
  color: #80738a;
}
.energySlider .leftLabel {
  left: 0;
}
.energySlider .rightLabel {
  right: 0;
}
.energySlider .valueHandle {
  position: absolute;
  top: 40px;
  margin-left: -50px;
  width: 100px;
  transition: left 0s;
  text-align: center;
  text-transform: uppercase;
  color: #e92263;
  font-size: 15px;
}

.activitySlider .leftLabel,
.activitySlider .rightLabel {
  position: absolute;
  top: -60px;
  text-transform: uppercase;
  color: #e92263;
}

@media (max-width: 500px) {
  .leftLabel,
  .rightLabel {
    font-size: 13px;
  }
}
