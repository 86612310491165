/*Question styles*/

/*drop down check boxes*/
.question_container .checkbox {
    display:block;
    position: relative;
    background-color: #f6f5f7; 
    border-radius: 25px;
    margin: 0;
    width:100%;
    box-sizing:border-box;
    font-family: 'futura-pt-medium';
    font-weight:500;
    font-size:15px;
}
.icon.drop_down_operator {
    position:absolute;
    right:15px;
    top:15px;
    cursor:pointer;
    display:inline-block;
    color:#E92263;
}
.question_container .checkbox.active {
    border-radius: 25px 25px 0 0;
}
.question_container .checkbox .checkbox_selector {
    height:50px;
    box-sizing:border-box;
    padding:10px 10px;
    cursor:pointer;
    position:relative;
    z-index:2;
    overflow:hidden;
}
.question_container .checkbox .checkbox_selector span {
    padding: 5px 20px;
    display:inline-block;
    font-size:15px;
    color: #80738a
}
.question_container .checkbox .checkbox_selector.filled span, .question_container .checkbox .checkbox_selector li.hidden {
    display:none;
}

.question_container .checkbox .checkbox_selector li, .plus_count {
    background-color:#a81758;
    color:#fff;
    display:inline-block;
    margin-right: 10px;
    border-radius: 15px;
    height:30px; 
    box-sizing:border-box;
    padding:5px 15px;    
    margin-bottom:10px;
}
.question_container .checkbox .checkbox_selector  span.plus_count {
    display:none; 
    color:#fff;
}
.question_container .checkbox .checkbox_selector .icon {
    color: #E92263;
    display: inline-block;
    position:absolute;
    right:15px;
    top:8px;
    z-index:1;
}  

.question_container .checkbox .checkbox_container {
    display:none;
}
.question_container .checkbox.active .checkbox_container {
    display:block;
    width:100%;
    position:absolute;
    background:#f6f5f7;
    border-radius: 0 0 25px 25px;
    z-index:3;
    column-count: 2;
    column-gap: 5px;
    padding-bottom: 10px;
}
.question_container .question_block_wide .checkbox.active .checkbox_container {
    column-count: 3;
    break-inside: avoid-column;
    page-break-inside: avoid;
}
.question_container .checkbox input[type="checkbox"], .question_container .checkbox input[type="radio"] {
    display:none;
}
.question_container .checkbox .checkbox_item {
    /*display:inline-block;*/
    /*min-width:120px;*/
    padding: 0px 10px 10px 10px;
    box-sizing: border-box;
    max-width:100%;
}

/*.question_block_wide .checkbox .checkbox_item {
    min-width:180px;
}   */ 
.question_container .checkbox input[type="checkbox"]+label, .question_container .checkbox input[type="radio"]+label {
    cursor:pointer;
    color:#80738a;
    position:relative;
    padding-left:20px;
}
.question_container .checkbox input[type="checkbox"]+label .icon, .question_container .checkbox input[type="radio"]+label .icon {
    margin: 0 10px;
    color:#80738a;
    position:absolute;
    top:3px;
    left:-10px;
}
.question_container .checkbox input[type="checkbox"]:checked+label .icon, .question_container .checkbox input[type="radio"]:checked+label .icon {
    color:#E92263;
}
@media (max-width: 500px) {
    .question_container .checkbox .checkbox_selector {
        padding: 10px 5px;
    }
    .question_container .question_block_wide .checkbox.active .checkbox_container {
        column-count: 2;
    }
}