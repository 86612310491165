button.chevronBackBtn {
  height: 45px;
  width: 45px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  font-family: "futura-pt-demi", sans-serif;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  outline: none;
  background-color: rgba(100, 89, 107, 0.5);
  color: #fff;
}

button.chevronBackBtn .svg-inline--fa {
  margin-top: 0;
}

button.chevronBackBtn:hover {
  background-color: #64596b;
  color: #fff;
  border: none;
  transition: none;
}

button.chevronBackBtn .icon,
button.chevronBackBtn:hover .icon {
  transition: none;
  margin-left: 0;
  margin-right: 0;
  margin-top: 3px;
}
