.glowrm_slider {
  overflow: auto;
  color: #fff;
  margin-top: -25px;
  position: relative;
}
/* #sleep.glowrm_slider {
  margin-top: 15px;
} */
.glowrm_slider .swiper {
  margin: 0px 0 30px 0;
  padding: 20px 0;
  height: 300px;
}

.glowrm_slider .swiper-slide {
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
}

span.ellipsis {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}
/* .glowrm.platform .glowrm_slider .swiper-slide {
  transition: margin 300ms;
} */

.glowrm_slider .swiper-slide:hover {
  margin-top: -5px;
}
.glowrm_slider .swiper:before,
.glowrm_slider .swiper:after {
  content: "";
  position: absolute;
  z-index: 10;
  width: 50px;
  top: 0;
  height: 100%;
  pointer-events: none;
}
.glowrm_slider .swiper:before {
  left: 0;
  /* background: linear-gradient(
    to right,
    rgba(63, 60, 71, 1),
    rgba(63, 60, 71, 0)
  ); */
}
.glowrm_slider .swiper:after {
  right: 0;
  /* background: linear-gradient(
    to left,
    rgba(63, 60, 71, 1),
    rgba(63, 60, 71, 0)
  ); */
  /*-webkit-mask-image: linear-gradient(90deg,rgba(255,255,255,0) 0%,rgba(0,0,0,1) 15%,rgba(0,0,0,1) 85%,rgba(255,255,255,0) 100%);
  mask-image: linear-gradient(90deg,rgba(255,255,255,0) 0%,rgba(0,0,0,1) 15%,rgba(0,0,0,1) 85%,rgba(255,255,255,0) 100%);  
*/
}
.glowrm_slider .swiper {
  -webkit-mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
}

.glowrm_slider.green .swiper {
  -webkit-mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 95%,
    rgba(255, 255, 255, 0.1) 100%
  );
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 95%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.glowrm_slider .swiper-pagination {
  text-align: right;
  width: 200px;
  padding-bottom: 10px;
}
.glowrm_slider .swiper-pagination-fraction,
.glowrm_slider .swiper-pagination-custom,
.glowrm_slider .swiper-horizontal > .swiper-pagination-bullets {
  top: 0px;
  right: 50px;
  bottom: auto;
  left: auto;
}

.apresults-content-sliders .glowrm_slider .swiper-pagination-bullets {
  top: auto;
  right: auto;
  bottom: 0px;
  left: auto;
  text-align: center;
  width: 300px;
}

.glowrm_slider .swiper-pagination-bullet {
  width: 20px;
  height: 4px;
  border-radius: 2px;
  background-color: #b1abaf;
}

.swiper-pagination-bullet.ap-class-bullet {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.glowrm_slider .swiper-pagination-bullet-active {
  background-color: #ea2364;
}

.swiper-pagination-bullet.ap-class-bullet.swiper-pagination-bullet-active {
  background-color: white;
}

.glowrm_slider .swiper-button-prev,
.glowrm_slider .swiper-button-next {
  color: #fff;
  z-index: 11;
  transition: margin 300ms;
  outline: none;
}
.glowrm_slider .swiper-button-prev {
  padding-right: 15px;
}
.glowrm_slider .swiper-button-prev:hover {
  margin-left: -5px;
  padding-right: 20px;
}
.glowrm_slider .swiper-button-next {
  padding-left: 15px;
}
.glowrm_slider .swiper-button-next:hover {
  margin-right: -5px;
  padding-left: 20px;
}

.apClassprev {
  left: 5px;
  right: auto;
}
.apClassprev:hover {
  margin-left: -5px;
  padding-right: 5px;
}
.apClassnext {
  right: 5px;
  left: auto;
}
.apClassnext:hover {
  margin-right: -5px;
  padding-left: 5px;
}

.innerSlide {
  cursor: pointer;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  display: flex;
  border-radius: 25px;
  position: relative;
  /* overflow: hidden; */
  background-position: center center;
  /* -webkit-mask-image: -webkit-radial-gradient(white, black); */
}

.glowrm_slider .swiper-slide.tall .innerSlide {
  overflow: hidden;
}

.slider-tooltip {
  position: absolute;
  width: 286px;
  z-index: 5;
  background: #64596b;
  border-radius: 12px;
  top: 44px;
  right: -131px;
  color: #fff;
}

.arrow:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 10px solid #64596b;
  border-top: 10px solid transparent;
  left: 126px;
  top: -19px;
}

.slider-tooltip .tooltip-row {
  height: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  font-family: "futura-pt-medium";
  font-size: 22.5px;
  column-gap: 10px;
}

.slider-tooltip .tooltip-row:nth-child(2) {
  border-bottom: solid 1px rgb(241, 235, 244, 0.05);
  border-top: solid 1px rgb(241, 235, 244, 0.05);
}

.content_slider.green .innerSlide::before {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 4px solid transparent;
  outline: 2px solid #62bf92;
  border-radius: 27px;
}

.content_slider.green .innerSlide .innerFade {
  background-image: linear-gradient(
    to bottom,
    rgba(40, 29, 45, 0.05) 0%,
    rgba(40, 29, 45, 0.7) 70%,
    rgba(40, 29, 45, 1) 100%
  );

  height: 100%;
}

.ta-badge {
  background-color: #62bf92;
  position: absolute;
  top: 12px;
  left: 12px;
  height: 45px;
  width: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.pb-badge {
  background-size: cover;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 101%;
}

.ta-shield {
  color: #000;
  height: 30px;
  width: 30px;
}

.innerSlide .innerFade {
  background-image: linear-gradient(
    to bottom,
    rgba(40, 29, 45, 0) 0%,
    rgba(40, 29, 45, 0.7) 40%,
    rgba(40, 29, 45, 1) 100%
  );
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 50%;
  box-sizing: border-box;
  border-radius: 0 0 22px 22px;
}
.innerSlide .innerContent {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  font-family: "futura-pt-medium";
  font-weight: 500;
}
.innerSlide .innerContent h2 {
  margin-bottom: 5px;
  font-family: "futura-pt-medium";
  font-size: 22px;
  font-weight: 500;
  width: 100%;
  color: #fff;
}
.innerSlide .innerContent .length {
  font-size: 18px;
  display: inline-block;
  width: 50%;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 600;
}
.innerSlide .innerContent .category {
  color: #b1abaf;
  text-transform: uppercase;
  font-size: 13px;
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
  text-align: right;
}

.innerSlide .innerContent .category.full_width {
  width: 100%;
}

.innerSlide .innerContent .progress_container {
  height: 6px;
  width: 100%;
  background-color: #4d4552;
  border-radius: 3px;
}
.innerSlide .innerContent .progress_bar {
  background-color: #ea2364;
  height: 6px;
  border-radius: 3px;
  /*transition: width 1s;*/
  width: 0%;
}

.glowrm_slider h1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: inline-block;
}

.glowrm_slider .cta {
  background-color: rgba(100, 89, 107, 0.8);
  border-radius: 25px;
  margin-right: 50px;
  text-transform: uppercase;
  padding: 10px 25px;
  font-size: 18px;
  color: white;
  font-family: "futura-pt-demi";
  position: absolute;
  right: 0;
  top: 24px;
}

.glowrm_slider .cta .icon {
  color: #fff;
  margin-left: 20px;
  transition: margin 200ms;
}
.glowrm_slider .cta .icon:hover {
  color: #fff;
  margin-left: 23px;
}
.ellipsis {
  position: absolute;
  height: 30px;
  width: 30px;
  z-index: 30;
  background-color: rgba(40, 29, 45, 0.2);
  border-radius: 15px;
  top: 5px;
  right: 5px;
  display: inline-block;
  text-align: center;
}

.ellipsis .ellipsis_menu.sbe {
  display: block;
  position: absolute;
  height: 195px;
  width: 325px;
  left: -150px;
  top: 30px;
  border-radius: 25px;
  background-color: #64596b;
  list-style: none;
  padding-left: 0;
}

.sbe:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 10px solid #64596b;
  top: -10px;
  left: 150px;
}

.ellipsis .ellipsis_menu.sbe li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  height: 65px;
  font-size: 22.5px;
}

.ellipsis .ellipsis_menu.sbe li.active {
  font-weight: 600;
}

.ellipsis li .icon {
  margin: 6px 12px;
}

.ellipsis .ellipsis_menu.sbe li:nth-child(1),
.ellipsis .ellipsis_menu.sbe li:nth-child(2) {
  border-bottom: solid 2px rgba(241, 235, 244, 0.05);
}

.ellipsis .icon {
  margin: 6px;
  color: #fff;
}

@media (max-width: 1050px) {
  .innerSlide .innerFade {
    height: 80%;
  }

  .glowrm_slider .cta {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .glowrm_slider .swiper-slide {
    padding: 10px 5px;
  }
  .innerSlide .innerContent {
    padding: 20px 10px;
  }
  .innerSlide .innerContent h2 {
    font-size: 18px;
  }
  .innerSlide .innerContent .category {
    display: none;
  }
  .innerSlide .innerContent .length {
    font-size: 15px;
  }
  .glowrm_slider h1 {
    margin-top: 0;
  }
  .swiper-pagination {
    display: none;
  }
}

@media (max-width: 500px) {
  .glowrm_slider .cta {
    font-size: 14px;
    margin-right: 5px;
    top: 15px;
  }
}
