.goals-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  height: 100%;
}

.single-goal-cont {
  padding: 60px 50px 20px 50px;
  width: calc(100% - 100px);
}

.goal-top-line,
.goal-top-line .goal-actions {
  font-family: "futura-pt-demi";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.goal-top-line .goal-title {
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin-bottom: 20px;
}

.goal-actions .goal-action {
  font-size: 1rem;
  cursor: pointer;
}

.goal-actions .goal-action:first-child {
  margin-right: 1.5em;
}

.goal-middle-line {
  font-family: "futura-pt-demi";
  font-size: 1rem;
  color: #ddcedb;
  margin-left: 4.2em;
}

.goal-graph-cont {
  padding-top: 1.4em;
  display: flex;
  gap: 1.5em;
  flex-direction: row;
  flex-wrap: wrap;
}

.chart-container.tile.third {
  width: calc(33% - 6.55em);
}

.chart-container.goal-half {
  width: calc(50% - 6.7em);
}
.chart-rectangle {
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 2em;
  margin-top: 1.8em;
}
.goal-grey-box {
  background-color: #403546;
  border-radius: 25px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ddcedb;
}

.goal-grey-box .icon.goals {
  height: 2.8rem;
}

.goal-grey-box-text {
  font-family: "futura-pt-medium";
  font-size: 1rem;
  padding: 1rem 2rem;
  text-align: center;
}

.goal-grey-box-button,
.goal-see-more {
  border-radius: 25px;
  color: #fff;
  font-family: "futura-pt-medium";
  letter-spacing: 1px;
  font-size: 1.15rem;
  padding: 0.4em 1.5em;
  text-transform: uppercase;
  cursor: pointer;
}

.goal-grey-box-button {
  background-color: #f69358;
  border: solid 1px #f69358;
}

.goal-grey-box-button:hover {
  background-color: #fff;
  color: #f69358;
  border: solid 1px #f69358;
}

.goal-see-more {
  background-color: #64596b;
  border: solid 1px #64596b;
}

.goal-see-more:hover {
  background-color: #fff;
  color: #64596b;
  border: solid 1px #64596b;
}
.media-container.goals {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  gap: 1em;
  height: 100%;
  width: 100%;
}

@media (max-width: 1190px) {
  .chart-container.goal-half {
    width: 100%;
  }
  /* .goal-grey-box-button,
  .goal-see-more {
    font-size: 0.8rem;
    padding: 0.4em 1em;
    text-align: center;
  } */
}

@media (max-width: 1060px) {
  .chart-container.tile.third {
    width: calc(50% - 6.7em);
  }
}

@media (max-width: 800px) {
  /* .chart-container.tile.third {
      width: 100%;
    } */
  .goals-container {
    transform: scale(0.9);
    padding-top: 0px;
    margin-top: -60px;
  }
  .single-goal-cont {
    padding: 0;
    width: 100%;
  }
  .nav_slider.na.goals {
    top: 62px;
    flex-wrap: wrap;
  }
}

@media (max-width: 720px) {
  .goal-top-line {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 650px) {
  .chart-container.tile.third {
    width: 100%;
  }

  .chart-container.goal-half {
    height: fit-content;
    .title-container {
      flex-direction: column;
    }
    .chart-rectangle {
      flex-direction: column;
      .goal-grey-box {
        width: 100%;
        padding: 25px 0;
      }
    }
  }
}

@media (max-width: 570px) {
  .goals-container {
    margin-top: -125px;
  }
}
