#main_container.foryou.glowrm.platform.results {
  padding: 0;
}

.page-cont {
  height: fit-content;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.page-cont.visible {
  opacity: 1;
}

.results-img {
  position: relative;
  width: 100%;
  height: 90vh;
}

.page-cont .gradient-overlay {
  position: absolute;
  top: 40%; /* Adjust to control where the gradient starts */
  width: 100%;
  height: 60%; /* Adjust to control the height of the gradient */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(40, 29, 45, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.hero-contents {
  padding: 0 130px;
}

.page-cont .gradient-overlay .hero-contents h1.hero-title {
  font-family: "futura-pt-demi";
  font-size: 6.25rem;
  line-height: 6.25rem;
  width: 60%;
  margin-left: 0;
}

.page-cont .gradient-overlay .hero-contents h3 {
  font-family: "futura-pt-medium";
  font-size: 1.25rem;
  color: #fff;
  width: 50%;
  margin-top: 2.2rem;
}

.page-cont .gradient-overlay .hero-contents p {
  color: #fff;
  margin: 0 0 15px 0;
}

button.open-tracker {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  margin: 0;
  padding: 18px;
}

button .add-tracker {
  height: 30px;
}

.results-nav {
  padding: 3rem;
}

.results-nav .results-menu {
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
}

.results-container {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  /* grid-template-columns: repeat(4, 1fr); */
  gap: 44px;
}

.results .results-container {
  padding: 0rem 3rem;
}

.take-action-cont {
  padding: 2.5rem 3rem 8rem 3rem;
}

.results-container .results-row {
  display: flex;
  flex-direction: row;
  /* column-gap: 44px; */
  gap: 44px;
  flex-wrap: wrap;
}

/* .results-container .results-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 44px;
} */

.results-container .financial-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 44px;
}
/* 
.results-container .results-row.half {
  width: calc(50% - 132px);
} */

/* .grid-item-extra-wide {
  grid-column: span 4;
}
.grid-item-wide {
  grid-column: span 2;
} */

/* .results-container h3.results-content-title, */
.results-container h3 {
  font-size: 30px;
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 0px;
  width: 100%;
}

.chart-container {
  padding: 40px 45px 35px 45px;
  /* width: calc(100% - 78px); */
  height: 300px;
  border-radius: 25px;
  border: 1px solid rgba(98, 90, 106, 0.95);
  display: flex;
  flex-direction: column;
  background-color: #281d2d;
}

.chart-container.half {
  width: calc(33% - 128px);
}

/* .chart-container.half.bars {
  height: auto;
}

.chart-container.half.bars .charts-box {
  flex-direction: column;
} */

.chart-container.quarter {
  width: calc(25% - 132px);
}

.chart-container.half.full-height {
  height: auto;
}

.chart-container.double-row {
  grid-row: 1 / 3;
  height: 490px;
}

.chart-container.info {
  justify-content: flex-start;
}

.chart-container .title-container,
.chart-container .charts-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chart-container .title-container {
  align-items: flex-start;
}

.title-container.empty {
  justify-content: flex-end;
  height: 35px;
}

.chart-container .charts-box {
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.chart-container .chart-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.chart-container .title-container .title,
.chart-container.tile .title,
.top-three-list .row .row-content .value {
  color: #fff;
  font-size: 2em;
  font-family: "futura-pt-demi";
}

.chart-container.tile .title.descr {
  height: 8.45rem;
  width: 80%;
  text-align: center;
}

.chart-container .title-container .icon {
  color: #ddcedb;
  height: 2em;
  width: 2em;
}

.chart-container .charts-box .chart-wrap {
  min-width: 515px;
  min-height: 265px;
}

.chart-container .chart-no-data {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}

.chart-container .charts-box .chart-no-data {
  position: absolute;
  top: 100px;
}

.chart-container .charts-box .average {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.chart-container .charts-box .average .title,
.chart-container .chart-no-data {
  color: #ddcedb;
  font-family: "futura-pt-medium";
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.chart-no-data.nd-tile {
  margin: 0;
}

.chart-container .charts-box .average .stats {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  border: 1px solid rgba(98, 90, 106, 0.95);
  color: #41c0d3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart-container .charts-box .average .stats .icon.alarm {
  height: 1.8em;
  width: 1.8em;
}

.chart-container .charts-box .average .stats .stat {
  font-family: "futura-pt-demi";
  font-size: 30px;
  height: 30px;
}

.chart-container .charts-box .average .stats .stat span {
  font-size: 15px;
}

.chart-container .charts-box .average .stats .unit {
  font-family: "futura-pt-medium";
  font-size: 15px;
  width: 90%;
  text-align: center;
}

.chart-container .charts-box .average .stats .av-descr {
  font-family: "futura-pt-medium";
  font-size: 12px;
}
.hydration-columns {
  display: flex;
  flex-direction: row;
}

@media (max-width: 2400px) {
  .chart-container.half.bars {
    height: auto;
  }

  .chart-container.half.bars .charts-box {
    flex-direction: column;
  }
}

@media (max-width: 1850px) {
  .chart-container.half {
    width: calc(50% - 128px);
  }

  .chart-container.half.bars {
    height: 300px;
  }

  .chart-container.half.bars .charts-box {
    flex-direction: row;
  }
}

@media (max-width: 1636px) {
  .chart-container.half.bars {
    height: auto;
  }

  .chart-container.half.bars .charts-box {
    flex-direction: column;
  }

  .chart-container.half.bars .charts-box .average {
    margin: 20px 0 0 0;
  }
}

@media (max-width: 1250px) {
  .results-container .results-row.half {
    width: 100%;
  }

  .chart-container.half.bars .charts-box {
    flex-direction: row;
  }

  .chart-container.half {
    width: 70%;
  }

  .chart-container.quarter {
    width: calc(33% - 132px);
  }
}

@media (max-width: 1100px) {
  .chart-container.half {
    width: 100%;
  }

  .chart-container.quarter {
    width: calc(50% - 128px);
  }

  .hero-contents {
    padding: 0 3em;
  }

  .page-cont .gradient-overlay .hero-contents h1.hero-title {
    width: 60%;
  }
  .page-cont .gradient-overlay .hero-contents h3 {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .page-cont .gradient-overlay .hero-contents h1.hero-title {
    font-size: 5.25rem;
    line-height: 5.25rem;
  }

  .page-cont .gradient-overlay {
    top: 30%;
    height: 70%;
  }
}

@media (max-width: 820px) {
  .chart-container.half.bars {
    height: auto;
  }

  .chart-container.half.bars .charts-box {
    flex-direction: column;
  }

  .results-nav .dates-toggle.with-dd div {
    padding: 0 45px 0 0;
  }

  .chart-container .title-container {
    font-size: 90%;
  }

  .results-nav .dates-toggle .period-dd {
    right: auto;
    left: 0;
  }
}

@media (max-width: 750px) {
  results-nav .dates-toggle div {
    padding: 0 25px;
  }
  .chart-container.quarter {
    width: 100%;
  }
  .results-nav {
    scale: 0.9;
    padding: 2rem 0 0 0;
    row-gap: 20px;
  }
  .chart-container .charts-box .chart-wrap {
    min-width: 98%;
    max-width: 98%;
  }
}

@media (max-width: 650px) {
  .results-nav {
    scale: 0.8;
  }
  .results-nav .results-toggle {
    width: 450px;
    height: 55px;
  }

  .results-nav .results-toggle div {
    line-height: 53px;
    font-size: 0.9rem;
  }

  button.open-tracker {
    height: 55px;
    width: 55px;
    padding: 12px;
  }

  .results-nav .dates-toggle {
    padding: 18px 40px;
    font-size: 0.9rem;
  }

  .results-nav .results-menu.tracker-data {
    flex-direction: column;
  }

  .results-nav .dates-toggle.with-dd {
    justify-content: space-between;
  }

  .info-box .info-sub-heading {
    flex-direction: column;
  }

  .chart-container.half.water {
    height: auto;
  }
}
@media (max-width: 520px) {
  .page-cont .gradient-overlay .hero-contents h1.hero-title {
    font-size: 4.25rem;
    line-height: 4.25rem;
    width: 80%;
  }
  .page-cont .gradient-overlay .hero-contents h3 {
    font-size: 1rem;
    width: 80%;
    margin-top: 1.2rem;
  }
  .results-nav {
    padding: 0;
  }
}

@media (max-width: 470px) {
  .results-container {
    margin: 0.5em;
    padding: 0;
  }

  .take-action-cont {
    padding: 0 0 7em 0;
  }

  .results-nav {
    scale: 1;
    margin: 0.5em;
    row-gap: 15px;
  }

  .results-nav .results-toggle {
    display: flex;
    flex-direction: column;
    height: auto;
    row-gap: 15px;
    width: fit-content;
  }

  .results-nav .results-menu {
    gap: 15px;
  }

  .results-nav .results-toggle .assessment,
  .results-nav .results-toggle .tracker {
    border-radius: 34px;
    width: 292px;
  }

  button.open-tracker {
    align-self: flex-end;
  }

  .hero-contents {
    padding: 0 0.5em;
  }

  .chart-container.double-row .title-container.fin .wtf {
    right: 10px;
  }

  .chart-container.double-row {
    height: auto;
  }
}

@media (max-width: 380px) {
  .chart-container .charts-box .chart-wrap {
    min-height: 250px;
    width: 250px;
  }

  .page-cont .gradient-overlay .hero-contents h1.hero-title {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }
  .page-cont .gradient-overlay .hero-contents h3 {
    font-size: 0.8rem;
    margin-top: 1rem;
  }

  .results-nav .results-toggle .assessment,
  .results-nav .results-toggle .tracker {
    border-radius: 34px;
    width: 200px;
    line-height: 40px;
    font-size: 0.7rem;
  }

  button.open-tracker {
    width: 42px;
    height: 42px;
    padding: 11px;
  }

  button .add-tracker {
    height: 20px;
  }

  .results-nav .dates-toggle .period-dd {
    top: 35px;
    font-size: 0.7rem;
    padding: 0;
    border-radius: 12px;
  }

  .results-nav .dates-toggle {
    padding: 13px 20px;
    font-size: 0.7rem;
  }

  .results-nav .dates-toggle div {
    padding: 0px 33px;
  }

  .chart-container.half {
    scale: 0.8;
  }

  .results-container .results-row {
    gap: 0;
  }

  .chart-container {
    scale: 0.8;
  }

  .top-three-list .row .row-content .value {
    font-size: 1em;
  }

  .results-container .financial-grid {
    gap: 0;
  }
}

/*
@media (max-width: 1350px) {
  .results-container .results-row.half.motivators {
    width: 100%;
  }
  .chart-container.quarter {
    width: 38%;
  }

  .chart-container.quarter.zero-grow {
    flex-grow: 0;
  }

  .results-container .results-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 44px;
  }

  .chart-container.half-grid {
    grid-column: span 1;
  }
}
/*
/*


@media (max-width: 775px) {
  .chart-container .charts-box .average .stats {
    height: 100px;
    width: 100px;
  }

  .chart-container .charts-box .average .stats .icon.alarm {
    height: 2.5em;
    width: 2.5em;
  }

  .chart-container .charts-box .average .stats .stat {
    font-size: 20px;
  }

  .chart-container .charts-box .average .stats .unit {
    font-size: 10px;
  }
}

@media (max-width: 750px) {
  /* .chart-container.wide {
    width: 530px;
  } */
/* .chart-container .charts-box .average {
    display: none;
  }
}

@media (max-width: 650px) {
  /* .chart-container.wide {
    width: 450px;
    padding: 40px 20px 35px 20px;
  } */

/* .chart-container .charts-box .chart-wrap {
    min-width: 380px;
    min-height: 250px;
  }
}

@media (max-width: 540px) {
  /* .chart-container.wide {
    width: 350px;
  } */

/* .chart-container .charts-box .chart-wrap {
    min-width: 380px;
    min-height: 190px;
  }
}

@media (max-width: 430px) {
  /* .chart-container.wide {
    width: 280px;
  } */

/* .chart-container .charts-box .chart-wrap {
    min-width: 300px;
    min-height: 80px;
  }
/* } */
