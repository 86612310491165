@keyframes roll-up-and-out {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes roll-down-and-out {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.scrolling-container {
  height: 30px;
  width: 20px;
  overflow: hidden;

  position: relative;
}

.scrolling-number {
  height: 60px;
  position: absolute;
  bottom: -15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scrolling-number .current {
  margin-top: 15px;
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrolling-number .current.up {
  animation: roll-up-and-out 0.15s linear;
}

.scrolling-number .current.down {
  animation: roll-down-and-out 0.15s linear;
}
